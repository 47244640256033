.TableMenu {
    align-items: center;
    border-bottom: 1px solid #09cab3;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.TableMenu h1 {
    padding: 0;
    margin: 0;
    font: normal normal normal 20px SF Pro Display;
    letter-spacing: 0;
}

.TableMenu .Label {
    font: normal normal normal 14px/30px SF Pro Display;
    letter-spacing: 0px;
    color: #B4B4B4;
    font-size: 16px;
    margin-right: 10px;
}

.TableMenu .Button {
    margin-left: 5px;
    font: normal normal bold 16px/19px SF Pro Display;
    letter-spacing: 0px;
    color: #09CAB3;
    text-transform: none;
}

.TableMenu .Search .Icon {
    color: #09CAB3;
}

.Table {
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}

.Table .Row {
    height: 50px;
    margin-bottom: 5px;
}

.Table .HeadCell {
    color: #bcbcbc;
    font-weight: bold;
}

.Table .HeadCellFirst {
    padding-left: 20px !important;
}

.Table .HeadCellLast {
    padding-right: 20px !important;
    border: none;
}

.Table .ValueCell,
.Table .CrudCell {
    background-color: white;
    font-size: 18px;
}

.Table .ValueCellFirst {
    border-radius: 5px 0 0 5px;
    padding-left: 20px !important;
}

.Table .ValueCellLast,
.Table .CrudCell {
    border-radius: 0 5px 5px 0;
    padding-right: 20px !important;
}

.Table .CheckboxCell {
    padding-right: 10px;
}

.Table .Cell,
.Table .HeadCell,
.Table .ValueCell,
.Table .CheckboxCell,
.Table .CrudCell {
    border-bottom: none;
    padding-left: 5px;
    font-family: SF Pro Display;
}

.Table .CrudCell .Button {
    margin-left: 5px;
    font: normal normal bold 20px/20px SF Pro Display;
    letter-spacing: 0px;
    color: #09CAB3;
}