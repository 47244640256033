.LoginBackground {
    background: url('../../images/background.png') no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100vh;
}
  
.LoginContainer {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    width: 60% !important;
}

.LogoSmall {
    background: url('../../images/logo_small.png') no-repeat;
    background-size: 100%;
    width: 165px;
    height: 90px;
    float: right;
    margin-right: 10px;
    display: flex;
    align-self: center;
}
  
.LoginForm {
    display: flex;
    margin-top: 50px;
}
  
.LoginForm h1 {
    font-size: 30px;
    font-weight: 400;
}
  
.LoginButton {
    background-color: #09cab3;
    color: white !important;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 20px 0;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 3px 6px #0000000F;
}
  
.LoginButton:hover {
    background-color: rgba(9, 202, 179, .8);
}

.RegisterButton {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
    color: #09cab3 !important;
    border-radius: 5px;
    border: 2px solid #09cab3;
    padding: 20px 0;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0px 3px 6px #0000000F;
}

.RegisterButton:hover {
    color: white !important;
    background-color: rgba(9, 202, 179, .8);
}

.LoginForgotGrid {
    padding: 5px 10px !important;
}

.LoginForgot {
    color: #808080;
    text-decoration: none;
    float: right;
    font-size: 13px;
}