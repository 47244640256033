/* Provide sufficient contrast against white background */
body {
    color: #434343;
    background-color: #f5f5f5;
    font-family: SF Pro Display;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: SF Pro Display !important;
}
a {
    color: #0366d6;
    cursor: pointer;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

/*
    Layout
*/
.app-container {
    min-height: 350px;
}

/*
    Sizing
*/
.height100 {
    height: 100%;
}

/*
    MUI
*/
.MuiFormControl-root {
    margin-top: 8px !important;
    margin-bottom: 4px !important;
}

.Mui-focused fieldset {
    border-color: #09cab3 !important;
}

label.Mui-focused {
    color: #09cab3 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #09cab3 !important;
}

/*
    Bootstrap
*/
.btn-link {
    text-decoration: none;
    color: #09cab3 !important;
    font: normal normal bold 16px SF Pro Display !important;
    letter-spacing: 0px;
    text-transform: none !important;
}

.btn-link:hover {
    color: rgba(9, 202, 179, 0.8);
}

/*
    Buttons
*/
.button {
    padding: 20px 0;
    border: none;
    border-radius: 5px;
    font: normal normal bold 14px SF Pro Display;
    text-transform: none !important;
}

.button:hover {
    opacity: 0.8;
}

.filled-button {
    background: #09CAB3 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 10px #10101033;
    color: white !important;
}

.outline-button {
    background: white 0% 0% no-repeat padding-box !important;
    color: #09CAB3 !important;
}

.table-menu-select {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 4px;
}

.table-menu-select .MuiFormControl-root {
    margin: 0 !important;
}

.table-menu-select .MuiAutocomplete-input {
    color: #09CAB3;
}

.table-menu-select:after {
    border-bottom: 2px solid #09CAB3 !important;
}

.table-menu-select button {
    padding: 0;
}

.table-menu-select button svg path {
    fill: #09CAB3;
}

.table-menu-select .MuiSelect-icon {
    color: #09CAB3;
}

.modified-form {
    display: flex;
    justify-content: center;
}

.modified-form.with-mt-50p {
    margin-top: 50px !important;
}

.modified-form label.Mui-focused,
.modified-form-filter label.Mui-focused {
    color: #09CAB3 !important;
}

.modified-form .Mui-focused fieldset,
.modified-form-filter .Mui-focused fieldset {
    border-color: #09CAB3 !important;
}

.modified-form input,
.modified-form textarea,
.modified-form .MuiSelect-select {
    font-size: 20px;
    padding: 5px 20px;
    line-height: 42px;
    height: unset;
}

.modified-form .MuiFilledInput-root {
    background-color: unset;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
}

.modified-form .MuiFilledInput-root.Mui-error {
    border-color: #d32f2f;
}

.modified-form .MuiFilledInput-root:after {
    border: none;
}

.modified-form .MuiFormControl-root {
    width: 100%;
}

.modified-form-filter.with-side-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.modified-form .with-error fieldset {
    border-color: #d32f2f;
}

.modified-form .with-error label {
    color: #d32f2f;
}

.file-upload-input {
    position: relative;
}

.file-upload-input.invalid {
    background-color: #d32f2f;
}

.file-upload-input input {
    outline: 2px dashed #09CAB3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

.file-upload-input.invalid input {
    outline: 2px dashed #d32f2f
}

.file-upload-input:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " lub upuść tutaj. ";
    display: block;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    text-align: center;
}

.file-upload-input.invalid:before {
    color: #d32f2f;
    content: "Wybranie pliku jest wymagane."
}

.file-with-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80%;
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
}

.menu-item-select {
    display: flex !important;
    justify-content: start !important;
    min-height: 40px !important;
    padding: 6px 16px !important;
  }

.tabs .MuiTabs-flexContainer {
    gap: 20px;
}

.tabs .tab-button {
    font-weight: 500;
}

.tabs .tab-button.Mui-selected {
    color: #09CAB3;
}

.tabs .MuiTabs-indicator {
    background-color: #09CAB3;
}